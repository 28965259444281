<template>
  <div id="specificHelp" class="contenedor text-center">
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-container fluid class="base text-center">
        <v-row
          no-gutters
          class="pa-0 justify-center"
          style="height: auto !important; width: 100% !important"
        >
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="justify-center"
          >
            <section>
              <h1>Ayuda</h1>
              <h4>Respuestas a preguntas frecuentes y consejos de expertos.</h4>
            </section>
          </v-col></v-row
        >
        <v-row
          no-gutters
          class="pa-0 justify-center help"
          style="height: auto !important; width: 100% !important"
        >
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-card>
              <v-card-title v-if="ayuda.nombreSeccion != null">{{
                ayuda.nombreSeccion
              }}</v-card-title>
              <v-card-title v-else>{{ ayuda.titulo }}</v-card-title
              ><v-divider></v-divider>
              <v-card-text class="text-justify" v-html="ayuda.contenido"></v-card-text>
              <v-divider></v-divider>
              <v-card-text class="text-justify pa-0">
                <v-row justify="center"
                  ><v-col cols="12" sm="12" md="6" lg="6" class="text-center"
                    ><a @click="sugerencia = true"
                      ><i class="far fa-comment mr-2"></i>Hacer un comentario o
                      sugerencia sobre este artículo</a
                    ></v-col
                  ><v-col cols="12" sm="12" md="6" lg="6" class="text-center"
                    ><a @click="calificacion = true"
                      ><i class="fas fa-medal mr-2"></i>Calificar información de
                      este artículo</a
                    ></v-col
                  ></v-row
                ></v-card-text
              >
            </v-card></v-col
          >
        </v-row>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ text }}
        </v-snackbar>
      </v-container>
    </v-container>
    <!--Calificacion-->
    <v-dialog v-model="calificacion" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Calificación de artículo</span>
        </v-card-title>
        <v-card-text>
          <!--Soluciono-->
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5" class="pt-0 ma-0 mt-3">
              <label for="autorizo"
                >El artículo soluciono su problema</label
              ></v-col
            >
            <v-col cols="12" sm="7" md="7" lg="7" class="mt-0 text-center">
              <v-radio-group v-model="soluciono" row class="ml-5">
                <v-radio label="No" value="false" color="red"></v-radio>
                <v-radio label="Sí" value="true" color="green"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!--Comentario-->
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="comentario">Comentario</label></v-col
            >
            <v-col cols="12" sm="7" md="7" lg="7">
              <textarea
                type="text"
                name="comentario"
                id="comentario"
                placeholder="Obligatorio"
                class="inputs"
                style="width: 170px !important"
                autocomplete="false"
                required
                maxlength="180"
                v-model="comentario"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              v-if="error"
              class="ma-0 pb-0"
            >
              <p style="color: red">
                *Favor de dar a conocer si ayudo a solucionar su problema
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              calificacion = false;
              soluciono = null;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="asignarCalificacion()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Sugerencia-->
    <v-dialog v-model="sugerencia" width="370">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Su opinion es muy importante</span>
        </v-card-title>
        <v-card-text>
          <!--Comentario-->
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <label for="sugerencias">Comentario</label></v-col
            >
            <v-col cols="12" sm="7" md="7" lg="7">
              <textarea
                type="text"
                name="sugerencias"
                id="sugerencias"
                placeholder="Obligatorio"
                class="inputs"
                style="width: 170px !important"
                autocomplete="false"
                required
                maxlength="180"
                v-model="sugerencias"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              v-if="error"
              class="ma-0 pb-0"
            >
              <p style="color: red">
                *Favor de seleccionar el cátalogo y/o describir su sugerencia.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              sugerencia = false;
              sugerencias = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="darSugerencia()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Sesion,
  },
  data() {
    return {
      expiration: false,
      calificacion: false,
      sugerencia:false,
      error: false,
      idAyuda: localStorage.idAyuda,
      idEmpleado: localStorage.empleadoIdGlobal,
      ayuda: {},
      catalogo:[],
      soluciono: null,
      comentario: "",
      snackbar: false,
      text: "Gracias por su tiempo.",
      timeout: 2000,
      idCatalogo:null,
      sugerencias:""
    };
  },
  methods: {
    asignarCalificacion() {
      this.error = false;
      if (this.soluciono == null) {
        this.error = true;
      } else {
        axios
          .post(
            Server + "/ayuda/calificacion/",
            {
              SeccionId: parseInt(this.idAyuda),
              UsuarioId: parseInt(this.idEmpleado),
              Soluciono: this.soluciono,
              Comentario: this.comentario,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            console.log();
            this.calificacion = false;
            this.comentario = "";
            this.soluciono = null;
            this.snackbar = true;
          })
          .catch((e) => {
            console.log(e);
            this.calificacion = false;
            this.comentario = "";
            this.soluciono = null;
            this.snackbar = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      }
    },
    darSugerencia() {
      this.error = false;
      if (this.sugerencias == "" || this.sugerencias == " ") {
        this.error = true;
      } else {
        axios
          .post(
            Server + "/ayuda/comentarios/",
            {
              SeccionId: parseInt(this.idAyuda),
              UsuarioId: parseInt(this.idEmpleado),
              TipoSugerenciaId: parseInt(this.idCatalogo),
              Sugerencias: this.sugerencias,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log("sugerencia",response);
            this.sugerencia = false;
            this.sugerencias = "";
            this.idCatalogo = null;
            this.snackbar = true;
          })
          .catch((e) => {
            //console.log(e);
            this.sugerencia = false;
            this.sugerencias = "";
            this.idCatalogo = null;
            this.snackbar = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      }
    },
    obtenerAyuda() {
      this.show = true;
      axios
        .get(Server + "/ayuda/" + this.idAyuda, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.ayuda = response.data;
          this.ayuda.contenido = this.ayuda.contenido.replace(/\n/g, "<br>");
          this.show = false;
        })
        .catch((e) => {
          //console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      axios
        .get(Server + "/ayuda/comentarios/catalogo-sugerencias/", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.catalogo = response.data;
          this.catalogo.forEach((value,index)=>{
            if(value.sugerencia=='Sin clasificar')
            this.idCatalogo= value.id;
          })
          this.show = false;
        })
        .catch((e) => {
          //console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    
    },
  },
  created() {
    this.obtenerAyuda();
  },
};
</script>
<style lang="sass" scoped>
a
  font-weight: bold
  color: grey !important
  &:hover
    color: orange !important
</style>